import React from 'react'

function CsvIcon(props) {
	return (
		<svg className='icon_csv' viewBox="0 0 256 256" x="0" y="0" {...props}>
			<g id="csv">
				<g>
					<path d="M184,168a8.009,8.009,0,0,0,8-8V112a8.009,8.009,0,0,0-8-8H48a8.009,8.009,0,0,0-8,8v48a8.009,8.009,0,0,0,8,8Zm-45.127-47.941,7.971,23.311,7.713-23.311H161.3l-11.279,31.5h-6.789l-11.258-31.5ZM122.615,140.5a5.242,5.242,0,0,0-2.288-1.364q-1.118-.387-5.092-1.375-5.114-1.267-7.176-3.115a8.2,8.2,0,0,1-2.9-6.338,8.1,8.1,0,0,1,1.364-4.5,8.538,8.538,0,0,1,3.932-3.19,15.756,15.756,0,0,1,6.2-1.1q5.93,0,8.927,2.6a9.177,9.177,0,0,1,3.147,6.939l-6.359.279a5.4,5.4,0,0,0-1.751-3.491,6.412,6.412,0,0,0-4.028-1.063,7.268,7.268,0,0,0-4.34,1.139,2.294,2.294,0,0,0-1.01,1.955,2.418,2.418,0,0,0,.945,1.912q1.2,1.01,5.844,2.105a30.856,30.856,0,0,1,6.864,2.267,8.848,8.848,0,0,1,3.48,3.2,9.915,9.915,0,0,1-.247,10.087,9.014,9.014,0,0,1-4.254,3.513,17.844,17.844,0,0,1-6.854,1.149q-5.973,0-9.174-2.761a12.006,12.006,0,0,1-3.824-8.046l6.188-.6a7.545,7.545,0,0,0,2.267,4.576,6.852,6.852,0,0,0,4.608,1.461,7.083,7.083,0,0,0,4.63-1.3,3.868,3.868,0,0,0,1.558-3.04A2.869,2.869,0,0,0,122.615,140.5ZM76.579,123.872a13.923,13.923,0,0,1,10.678-4.351,13.1,13.1,0,0,1,9.389,3.416,12.429,12.429,0,0,1,3.223,5.8l-6.295,1.5a6.506,6.506,0,0,0-6.639-5.285,7.166,7.166,0,0,0-5.683,2.514q-2.181,2.514-2.181,8.143,0,5.973,2.148,8.508a6.975,6.975,0,0,0,5.586,2.535,6.371,6.371,0,0,0,4.361-1.611,9.362,9.362,0,0,0,2.621-5.07l6.166,1.955a13.831,13.831,0,0,1-4.716,7.659,13.45,13.45,0,0,1-8.368,2.5,13.547,13.547,0,0,1-10.312-4.286q-4.039-4.286-4.039-11.72Q72.519,128.223,76.579,123.872Z" />
					<path d="M200,8H64A16.018,16.018,0,0,0,48,24V96h8V24a8.009,8.009,0,0,1,8-8H200a8.009,8.009,0,0,1,8,8V191.029a8.113,8.113,0,0,1-.065.971H172a12.014,12.014,0,0,0-12,12v35.935a8.113,8.113,0,0,1-.971.065H64a8.009,8.009,0,0,1-8-8V176H48v56a16.018,16.018,0,0,0,16,16h95.029a15.9,15.9,0,0,0,11.314-4.686l40.971-40.971A15.9,15.9,0,0,0,216,191.029V24A16.018,16.018,0,0,0,200,8Z" />
				</g>
			</g>
		</svg>
	)
}

export default CsvIcon
